import React, { useState } from 'react'
import Layout from '../components/layout'
import { Container, Row, Col, Card, CardHeader, Collapse, CardBody, Button, Form, FormGroup, Input } from 'reactstrap'
//import banner from '../images/Insurance.jpg'
import { BookAnAppoinment } from '../components/book0an-apoinment'
import logo from '../images/logo-black.png'
import golf from '../images/insurant-restibg.jpg'
import { seoData } from '../data/seo-data'
import liveInsurence from '../images/ins.png'
import { BookAFreeConsultations } from '../components/book-a-free-consultations'
import insBg from '../images/ins-bg.jpg'
import sedi from '../images/sedi.png'
import senang from '../images/senang.png'
import { Helmet } from 'react-helmet'
const PersonalSolutions = () => {

    const [uid, setUid] = useState("")
    
    const steps = [
        {
            id: 1,
            text: "Life insurance",
            content: <>
                <p>In the event of your death will your loved ones be covered financially? </p>
                <p>The level of life insurance cover you will need depends on your individual circumstances and this is where we are able to guide you through the process.</p>
                <p>When calculating the amount required we will take into consideration expenses such as paying off your mortgage and other debts, general living expenses such as childcare and education and the cost of running a household.</p>
                <p>Various sources such as superannuation, shares, savings and existing insurance policies may offset the amount of cover you require.</p>

            </>
        }, {
            text: "Income Protection",
            id: 2,
            content: <>
                <p>Income protection insurance pays you a benefit if you are unable to work for a certain period of time because of illness or injury. This is generally paid monthly, but can be paid as a lump sum in certain circumstances.</p>
                <p>Policies will insure you for a set percentage of your current regular income (commonly up to 75% of your gross salary) for up to a certain duration known as the benefit period. In the event of a successful claim, your income protection insurance will be paid after the relevant waiting period, as specified in your policy, for example 30 days. After the waiting period, the policy should pay you until you are able to return to work. </p>

            </>
        }, {
            text: "Total & Permanent Disability",
            id: 3,
            content: <>
                <p>Total and Permanent Disability (TPD) cover pays you a lump sum if you become totally and permanently disabled. This could help cover the costs of rehabilitation, debt repayments and the future cost of living.</p>
                <p>The definition of total and permanent disability varies between insurance companies, but it generally means that you are disabled to the extent that you will probably be unable to work again. </p>
                <p>When determining the amount, we will work with you to understand your living expenses, repaying debts and mortgage, medical and rehabilitation costs, and any savings you want for retirement.</p>
            </>
        }, {
            text: "Trauma Cover / Crisis recovery",
            id: 4,
            content: <>
                <p>Trauma insurance can provide a lump sum of money to help you meet medical expenses and other financial needs when you have suffered a medical trauma.</p>
                <p>While the type of medical traumas covered will differ between policies, some of the more commonly-defined events are cancer, heart attack and stroke.</p>
                <p>Unlike Income Protection, Trauma insurance will be paid to you in a lump sum with no waiting period.  It can be used to support your living expenses as well as your partner and or family during the recovery period and/or treatment.</p>
                <p>It's common to think if you already have Income Protection you don't need Trauma cover, however there are circumstances where you may not qualify for an Income Protection payment, but you will qualify for Trauma payment.  <br />
This is due to the fact that while you are getting treatment- cancer for example- you will generally not be away from work long enough to meet an Income Protection waiting period requirement however your income may reduce.
</p>
                <p>Depending on the severity of the Trauma event, treatment costs may also be a factor.</p>
                <p>One other thing to remember is that people who have experienced a significant health event in their lives generally want to take additional time away from their weekly routine and be able to come to terms with their diagnosis or spend time with their loved ones.</p>
            </>
        }
    ]
    const [collapses, setCollapses] = React.useState([0]);
    const changeCollapse = (collapse: any) => {
        if (collapses.includes(collapse)) {
            setCollapses(collapses.filter(prop => prop !== collapse));
        } else {
            setCollapses([...collapses, collapse]);
        }
    };

    const help: any[] = []

    for (const x of steps) {
        help.push(
            <Col md={6} key={x.id}>
                <Card className="card-plain">
                    <CardHeader style={{ backgroundColor: '#016b92', padding: '5px 10px', fontSize: 18 }} id="headingOne" role="tab">
                        <a
                            aria-expanded={collapses.includes(x.id)}
                            data-toggle="collapse"
                            href="#pablo"
                            style={{ color: '#fff' }}
                            onClick={e => {
                                e.preventDefault();
                                changeCollapse(x.id);
                            }}
                        >
                            {x.text}
                            <i className="now-ui-icons arrows-1_minimal-down" style={{ float: 'right' }}></i>
                        </a>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(x.id)}>
                        <CardBody>
                            {x.content}
                        </CardBody>
                    </Collapse>
                </Card>
            </Col>
        )
    }


    return (
        <Layout
            seo={{
                title: seoData.personalSolutions.title, description: seoData.personalSolutions.description, keyword: ''
            }}
            banner={<FreeConsulTationBanner />}
            navStayle="SIMPLE"
            setUid={(uid: string) => setUid(uid)}
        >
            <section style={{ marginBottom: 50, marginTop: 50 }}>
                <Container >
                    <Row className="justify-content-md-center" style={{ marginBottom: 50 }} >
                        <Col md={12} style={{ textAlign: "center" }}>
                            <h3>Protecting What’s Most Important</h3>
                            <p>The purpose of life insurance is to provide you and your family with financial security if you were to pass away, become terminally ill, or if you become unable to work due to illness or injury.  Unfortunately many people decide to take up insurance when it's too late.</p>
                        </Col>
                        <Col md={6}  >
                            <img src={liveInsurence} alt="" />
                        </Col>
                        <Col md={6} >
                            <p>Everyone needs a backup plan in case life presents unforeseen challenges. You want to be covered and know that your family has a safety net. </p>
                            <p>We’ll help you plan for the worst, and help you find the best coverage plans available, and tailor a payment structure suited to your financial position.  After all, these insurances are designed to help you live a secured life, not impede on your lifestyle.</p>
                            <a href="#contact" className="btn " style={{backgroundColor: '#ffb236'}}><span style={{ fontSize: 14 }}> Let’s have a chat about how we can help you</span></a>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <h3>How much does Insurance cost? </h3>
                            <p>The cost and availability of insurance may vary depending on a number of factors including your age, gender and your health including whether or not you smoke. </p>
                            <p>You can choose to pay for cover either through your superannuation (with the exception of Trauma cover) or from your income.  We will help you decide the best payment structure for you as each payment method has its advantages depending on your personal circumstances.</p>
                        </Col>
                        <Col md={6}>
                            <h3>How can Solutions help?</h3>
                            <p>We will look at your financial position and then decide on a plan suitable to your circumstances, and how to best fund it. We get paid commissions from insurance providers (just like a mortgage broker) which covers our cost of researching an insurance solution for you as well as producing the advice documentation.</p>
                            <p>For any additional financial plans prepared such as retirement planning, our team will discuss a tailored fee structure with you.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={{ marginBottom: 50, marginTop: 50 }}>
                <Container >
                    <Row>
                        <Col>
                        <p>There are different types of cover, which can be bought separately or bundled into one policy. You may require one or more of these types of cover, depending on your circumstances.</p>
                        </Col>
                    </Row>
                    <Row>
                    
                        {help}
                    </Row>
                </Container>
            </section>
            <section id="contact" style={{ padding: "100px 0 50px", backgroundColor: "#fff" }}>
                <BookAFreeConsultations  uid={uid}/>
            </section>
            <section style={{ marginBottom: 50, padding: "150px 0 0 0", background: `url(${golf})`, backgroundSize: 'cover', backgroundPosition: 'center right' }}>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div style={{ backgroundColor: 'rgba(1, 107, 146, 0.88)', padding: 20, color: '#fff', marginTop: 130 }}>
                                <p style={{ fontWeight: 500 }}><i className="fas fa-quote-left pr-2"></i> Marc set me on the right financial track, when 18 months ago, my wife passed away, and his understanding was invaluable. He looked after all of Mary’s superannuation & insurance claims in a kind & professional manner. <i className="fas fa-quote-right pr-2" /></p>
                                <p style={{ color: '#d4ca5a', fontWeight: 800 }}>- Andrew MacDougall  -</p>
                            </div>
                        </Col> 
                    </Row>
                </Container>
            </section>
            <BookAnAppoinment />
        </Layout>
    )
}

const FreeConsulTationBanner = () => {
    return (
        <>
        <Helmet>
            <style>
                {
                    `
                    h2.ins {
                        font-size: 30px; 
                    }
                    @media screen and (max-width: 991px) {
                        h2.ins {
                            font-size: 20px; 
                        }
                    }
                    `
                }
            </style>
        </Helmet>
            <div style={{ backgroundImage: `url(${insBg})` , backgroundPosition: 'top center', minHeight: 600 }}>
                <Container>
                    <Row style={{ paddingBottom: 30, textAlign: "center" }}>
                        <Col md={12} >
                        <div style={{ marginTop: '100px', padding: '10px 0' }}>
                                <h3 className="header-font" style={{ fontSize: '4.2em', marginBottom: 0, width: 'auto', color: '#fff' }}>Insurance </h3>
                                <img style={{ marginTop: -37 }} src={logo} alt="" />
                            </div>
                        </Col> 
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col >
                        <h2 className="ins" style={{fontWeight: 800, textTransform: 'uppercase', color: '#fff'}}>I finally <br/>
got personal <br/>
insurance <br/>
cover…</h2>
                            <img src={sedi} alt="" />

                        </Col>
                        <Col >
                        <h2  className="ins" style={{fontWeight: 800, textTransform: 'uppercase' }}>
                        without<br/>
paying<br/>
out of<br/>
pocket!
                        </h2>
                        <img src={senang} alt="" />
                        </Col>

                    </Row>
                </Container>
            </div>

        </>
    )
}

export default PersonalSolutions

